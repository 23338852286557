import { Box, Typography } from '@mui/material';
import React from 'react';

const App: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography
        variant="h3"
        sx={{ fontWeight: 'bold', color: '#333', marginBottom: '16px' }}
      >
        CodeCraft Solutions
      </Typography>
      <Typography variant="h5" sx={{ color: '#555' }}>
      🚧 is under construction 🚧
      </Typography>
    </Box>
  );
};

export default App;